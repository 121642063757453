// pages/404.js
import PageLayout from "@/screens/Layout/PageLayout";
//import Link from "next/link";
import { useRouter } from "next/router";

const NotFoundText = () => {
  return (
    <div className="flex items-center justify-center w-full h-screen bg-gray-100">
      <div className="text-center animate-pulse">
        <h1 className="text-6xl font-bold text-gray-800">404</h1>
        <p className="text-xl font-light text-gray-600">Page not found</p>
        <a href="/" className="mt-6 text-primary hover:underline">
          Go back home
        </a>
      </div>
    </div>
  );
};

export default function Custom404() {
  const router = useRouter();
  const isAmp = router?.asPath?.startsWith("/amp");

  return isAmp ? (
    <>
      <NotFoundText />
    </>
  ) : (
    <PageLayout>
      <NotFoundText />
    </PageLayout>
  );
}
