import dynamic from "next/dynamic";
import useIsMobile from "@/hooks/useIsMobile";
import { useRouter } from "next/router";
const RightSideBar = dynamic(() => import("@/component/Sidebar/RightSideBar"), {loading: ()=><></>});
const Footer = dynamic(() => import("@/component/Footer"), {loading: ()=><></>, ssr:false});
const BackToTopButton = dynamic(() => import("@/component/Buttons/BackToTop"));

const PageLayout = ({ children, ...props }) => {
   
  const isMobileDevice = useIsMobile();
  const router = useRouter();
  const isRajasthan = router?.asPath?.includes("/rajasthan");
  const isMadhya = router?.asPath?.includes("/madhya-pradesh");
  const isHR = router?.asPath?.includes("/haryana");
  const isBH = router?.asPath?.includes("/bihar");
  const isUP = router?.asPath?.includes("/uttar-pradesh");

  return (
    <section className="md:flex">
      <section className="md:w-[72%]">
        {children}
        <Footer
          isMobileDevice={isMobileDevice}
          latestVideoData={props.latestVideoData  ? props.latestVideoData?.content : (props.rightSideBarData?.right?props.rightSideBarData?.right[1]?.map((item) => item?.content)[0]:null)}
          latestStoryData={props.latestStoryData ? props.latestStoryData?.content : (props.rightSideBarData?.right?props.rightSideBarData?.right[0]?.map((item) => item?.content)[0]:null)}
        />
      </section>
      <section className="md:w-[28%]">
        {!isMobileDevice && <RightSideBar {...props} />}
      </section>
      <BackToTopButton isRajasthan={isRajasthan} isMadhya={isMadhya} isHR={isHR} isBH={isBH} isUP={isUP} />
    </section>
  );
};

export default PageLayout;
